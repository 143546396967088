import { Box, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { datePayload } from "../../../../utils/moduleTabApi";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import instance from "../../../../utils/axiosHelper";
import TrendBar from "../trend/TrendBar";

const useStyles = makeStyles(() => ({
  trendContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: 24,
    padding: "8px",
  },
}));

const BenchmarkModelTrend = ({
  showTrend,
  handleCloseTrend,
  metricName,
  date,
}) => {
  const styles = useStyles();
  const { projectId } = useParams();
  const [trendArrayContainer, setTrendArrayContainer] = useState([]);

  // API Url call
  useEffect(() => {
    if (trendArrayContainer) {
      setTrendArrayContainer([]);
    }
    if (!metricName?.trendName) return;
    const dateUrl = datePayload(date);
    if (metricName?.trendName === "Team Speedy Transition") {
      const url = `/api/v2/projects/speedyTransitionTrend?projectId=${projectId}&${dateUrl}&teamName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Resource Speedy Transition") {
      const url = `/api/v2/projects/speedyTransitionTrend?projectId=${projectId}&${dateUrl}&resourceName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Team Unreviewed Pr") {
      const url = `/api/v2/projects/prReviewUnreviewTrend/${projectId}?${dateUrl}&iteration=30&teamName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Resource Unreviewed Pr") {
      const url = `/api/v2/projects/prReviewUnreviewTrend/${projectId}?iteration=30&${dateUrl}&resourceName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Team Flow Efficiency") {
      const url = `/api/v2/projects/flowEfficiencytrend/${projectId}?iteration=30&${dateUrl}&teamName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Resource Flow Efficiency") {
      const url = `/api/v2/projects/flowEfficiencytrend/${projectId}?iteration=30&${dateUrl}&resourceName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Team Commit With Pr") {
      const url = `/api/v2/projects/commitwithpullreqTrend/${projectId}?iteration=30&${dateUrl}&teamName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Resource Commit With Pr") {
      const url = `/api/v2/projects/commitwithpullreqTrend/${projectId}?iteration=30&${dateUrl}&resourceName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Team Average Timediff") {
      const url = `/api/v2/projects/prReviewTimeTrend/${projectId}?iteration=30&${dateUrl}&teamName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Team Commit Without Ref") {
      const url = `api/v2/projects/commitwoticketrefTrend/${projectId}?iteration=30&${dateUrl}&teamName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Resource Commit Without Ref") {
      const url = `api/v2/projects/commitwoticketrefTrend/${projectId}?iteration=30&${dateUrl}&resourceName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Team Total Commits Count") {
      const url = `/api/v2/compare/CommitCountTrend?projectId=${projectId}&iteration=30&${dateUrl}&teamName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Resource Total Commits Count") {
      const url = `/api/v2/compare/CommitCountTrend?projectId=${projectId}&iteration=30&${dateUrl}&resourceName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Team Pr Count") {
      const url = `/api/v2/compare/prCountTrend?projectId=${projectId}&iteration=30&${dateUrl}&teamName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Resource Pr Count") {
      const url = `/api/v2/compare/prCountTrend?projectId=${projectId}&iteration=30&${dateUrl}&resourceName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Team Avg Churn") {
      const url = `/api/v2/teams/codechurnTrend/${projectId}?iteration=30&${dateUrl}&teamName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Resource Avg Churn") {
      const url = `/api/v2/teams/codechurnTrend/${projectId}?iteration=30&${dateUrl}&resourceName=${metricName?.data?._id}`;
      apiCall(url);
    } else if (metricName?.trendName === "Resource Average Timediff") {
      const url = `/api/v2/projects/prReviewTimeTrend/${projectId}?iteration=30&${dateUrl}&resourceName=${metricName?.data?._id}`;
      apiCall(url);
    }
  }, [date, metricName, projectId]);

  // API Call Function
  const apiCall = (url) => {
    instance
      .get(url)
      .then((response) => {
        if (metricName?.trendName === "Team Speedy Transition") {
          const speedyTrend = response?.data?.trends?.map((name) => {
            return {
              category: name?.startDate,
              Percent: name?.percent,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Resource Speedy Transition") {
          const speedyTrend = response?.data?.trends?.map((name) => {
            return {
              category: name?.startDate,
              Percent: name?.percent,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Team Unreviewed Pr") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Unreviewed Pr": name?.UnreviewedPR,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Resource Unreviewed Pr") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Unreviewed Pr": name?.UnreviewedPR,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Team Flow Efficiency") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              Efficiency: name?.Efficiency,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Resource Flow Efficiency") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              Efficiency: name?.Efficiency,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Team Commit With Pr") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Commit With Pr": name?.commitwithprnumber,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Resource Commit With Pr") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Commit With Pr": name?.commitwithprnumber,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Team Average Timediff") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Review Time": name?.reviewTime,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Team Commit Without Ref") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Commit Without Ticket Reference": name?.CommitwoTicket,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Resource Commit Without Ref") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Commit Without Ticket Reference": name?.CommitwoTicket,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Team Total Commits Count") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Commit (Count)": name?.CommitCount,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Resource Total Commits Count") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Commit (Count)": name?.CommitCount,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Team Pr Count") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Commit (Count)": name?.prCount,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Resource Pr Count") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Commit (Count)": name?.prCount,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Team Avg Churn") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "code churn": name?.codechurn,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Resource Avg Churn") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "code churn": name?.codechurn,
            };
          });
          setTrendArrayContainer(speedyTrend);
        } else if (metricName?.trendName === "Resource Average Timediff") {
          const speedyTrend = response?.data?.map((name) => {
            return {
              category: name?.StartDate,
              "Review Time": name?.reviewTime,
            };
          });
          setTrendArrayContainer(speedyTrend);
        }
      })
      .catch((error) => {
        setTrendArrayContainer([]);
        console.log(`error ${metricName?.trendName} ${error}`);
      });
  };

  const getLabel = (name) => {
    switch (name) {
      case "Team Speedy Transition":
      case "Resource Speedy Transition":
        return "Speedy Transition (%)";
      case "Team Flow Efficiency":
      case "Resource Flow Efficiency":
        return "Flow Efficiency (%)";
      case "Team Unreviewed Pr":
      case "Resource Unreviewed Pr":
        return "Unreviewed Pr (Count)";
      case "Team Commit With Pr":
      case "Resource Commit With Pr":
        return "Commit With Pr (%)";
      case "Team Average Timediff":
      case "Resource Average Timediff":
        return "Review Time (Day's)";
      case "Team Total Commits Count":
      case "Resource Total Commits Count":
        return "Total Commits (Count)";
      case "Team Pr Count":
      case "Resource Pr Count":
        return "Pull Request (Count)";
      case "Team Avg Churn":
      case "Resource Avg Churn":
        return "Avg Churn (Count)";
      default:
        return null;
    }
  };

  const yAxesLabel = getLabel(metricName?.trendName);

  return (
    <Modal
      open={showTrend}
      onClose={handleCloseTrend}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.trendContainer}>
        <h4 className="mx-4 mt-2 mb-4">{metricName?.name} Trend</h4>
        {(metricName?.trendName === "Team Speedy Transition" ||
          metricName?.trendName === "Resource Speedy Transition" ||
          metricName?.trendName === "Team Unreviewed Pr" ||
          metricName?.trendName === "Resource Unreviewed Pr" ||
          metricName?.trendName === "Team Commit With Pr" ||
          metricName?.trendName === "Resource Commit With Pr" ||
          metricName?.trendName === "Team Average Timediff" ||
          metricName?.trendName === "Team Total Commits Count" ||
          metricName?.trendName === "Resource Total Commits Count" ||
          metricName?.trendName === "Team Commit Without Ref" ||
          metricName?.trendName === "Resource Commit Without Ref" ||
          metricName?.trendName === "Team Avg Churn" ||
          metricName?.trendName === "Team Pr Count" ||
          metricName?.trendName === "Resource Pr Count" ||
          metricName?.trendName === "Resource Avg Churn" ||
          metricName?.trendName === "Resource Average Timediff" ||
          metricName?.trendName === "Resource Flow Efficiency" ||
          metricName?.trendName === "Team Flow Efficiency") && (
          <TrendBar
            graphData={trendArrayContainer}
            yAxesLabel={yAxesLabel ? yAxesLabel : ""}
            issueType={metricName?.trendName}
          />
        )}
      </Box>
    </Modal>
  );
};

export default BenchmarkModelTrend;
