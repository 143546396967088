import { Box, Divider, Grid, Slider, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { addSpace } from "../../../../utils/spaceSeprator";
import BenchmarkModelTrend from "./BenchmarkModel";

const useStyles = makeStyles(() => ({
  metricName: {
    width: "50%",
    fontSize: "16px",
    color: "gray",
  },
  metricValue: {
    width: "20%",
    fontSize: "16px",
    color: "black",
  },
  viewTrend: {
    width: "15%",
    fontSize: "12px",
    color: "#5ebdcc",
    border: "1px solid #5ebdcc",
    textAlign: "center",
    borderRadius: "4px",
    cursor: "pointer",
  },
  root: {
    "& .MuiSlider-thumb": {
      background: "black",
      width: "3px",
      height: "5px",
      borderRadius: "0px",
    },
    "& .MuiSlider-rail": {
      background:
        "linear-gradient(90deg, rgba(255,109,112,1) 28%, rgba(255,241,240,1) 53%, rgba(0,255,70,1) 75%)",
      height: "6px", // Adjust this value as needed to increase the height
    },
  },
  tooltip: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "red", // This will now correctly apply
    },
  },
}));

const CardSection = ({ cardItems, date,selectedTeam }) => {
  const benchCss = useStyles();
  const [showTrend, setShowTrend] = useState(false);
  const [metricData, setMetricData] = useState({});
  console.log(selectedTeam,"<-----selectedTeam")

  // Models Function
  const showTrendPopup = (item, name) => {
    setShowTrend(true);
    setMetricData({ data: item, name: name ,trendName: selectedTeam  === 'Team'? `Team ${name}` : `Resource ${name}`});
  };
  const handleCloseTrend = () => {
    setShowTrend(false);
  };

  return (
    <div className="p-4">
      <Tooltip
        title={
          <Box fontSize={"14px"}>
            <div>
              <b>Green</b> : The team/contributor has a better value than the
              average.
            </div>
            <div>
              <b>Red</b> : The team/Contributor has less value than the average.
            </div>
          </Box>
        }
        placement="top"
        arrow
        // className={benchCss.tooltip}
        classes={{ tooltip: benchCss.tooltip }} // Apply tooltip class
      >
        <Box width={"30%"} mx={"auto"}>
          <Slider
            aria-label="Volume"
            value={50}
            track={false}
            color="secondary"
            className={benchCss.root}
            size="medium"
            valueLabelDisplay="auto"
          />
        </Box>
      </Tooltip>
      <Grid container spacing={2}>
        {cardItems?.map((item, index) => {
          return (
            <Grid item xs={4}>
              <Box className="border shadow-sm rounded">
                <h5 className="p-2">{item?._id}</h5>
                <Divider />
                <div className="p-2">
                  {Object.keys(item)
                    .filter(
                      (color) =>
                        !color.includes("Color") &&
                        !color.includes("Percentile") &&
                        color !== "_id"
                    )
                    .map((e, index) => {
                      return (
                        <div
                          className="d-flex align-items-start  pb-1"
                          key={index}
                        >
                          <div className={benchCss.metricName}>
                            {addSpace(e)}{" "}
                            <span style={{ color: "black" }}>({item[e]})</span>
                          </div>
                          <Box width={"30%"} mx={"auto"}>
                            <Slider
                              aria-label="Volume"
                              value={Math.round(item[`${e}Percentile`])}
                              track={false}
                              color="secondary"
                              className={benchCss.root}
                              size="medium"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                          <div
                            className={benchCss.viewTrend}
                            onClick={() => showTrendPopup(item, addSpace(e))}
                          >
                            View Trend
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <BenchmarkModelTrend
        showTrend={showTrend}
        handleCloseTrend={handleCloseTrend}
        metricName={metricData}
        date={date}
      />
    </div>
  );
};

export default CardSection;
